body {
  margin: 0px;
  min-height: 100%;
  padding: 0px;
  position: absolute;
  width: 100%;
  font: 16px "Segoe UI", "Open Sans", arial, sans-serif !important;
  font-weight: 300 !important;
}
input[type="checkbox"]:focus,
a:focus,
button:focus {
  outline: none !important;
}
@media (min-width: 768px) {
  body {
    font-size: 16px !important;
  }
}
.h3 {
  font-weight: 300 !important;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 34px;
}

h4 {
  font-size: 26px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

a {
  font-weight: 400;
}

form#label_form {
  font-size: 14px;
}

/* Bound Map */

#mapdiv {
  width: 100%;
  height: 450px;
  margin-bottom: 20px !important;
}

#bbox_drag_instruction {
  padding: 6px;
  margin-bottom: 12px;
  border: 1px green solid;
  border-radius: 5px;
}

#bbox_adjust_instruction {
  padding: 6px;
  margin-bottom: 12px;
  display: none;
  border: 1px green solid;
  border-radius: 5px;
  padding-left: 16px;
}

#mapport {
  color: #0085da;
  font-size: 18pt;
  margin-right: 10px;
}

#mapport > span#site_subtitle {
  font-size: 12pt;
}

#logo_placeholder {
  display: inline;
  height: 30px;
  min-width: 30px;
  margin-right: 5px;
  margin-top: -3px;
}

#main-prod-header #logo_placeholder {
  display: inline;
  height: 48px;
  min-width: 30px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

#userinfo {
  float: right;
  height: 40px;
  line-height: 40px;
}

html,
body {
  height: 100%;
}

.navbar.whitebar ~ #map {
  top: 80px;
}

#map {
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  padding: 0;
  position: fixed;
  right: 0;
  top: 40px;
  z-index: 0;
}

#map-overlay {
  position: absolute;
  z-index: 99;
  width: 100%;
  background: rgba(0, 0, 0, 0.45);
  height: 100%;
  color: white;
  text-align: center;
  font-size: 30px;
  padding-top: 22%;
  overflow: auto;
}

.navbar {
  z-index: 1000;
}
.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 0px;
  border: 1px solid transparent;
  /*margin-top: 10px;*/
  border-radius: unset !important;
}
#loading,
.loader {
  background: transparent url("/assets/img/wheel.svg") no-repeat scroll center
    center;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
}

.no-js #loader {
  display: none;
}

.js #loader {
  display: block;
  position: absolute;
  left: 100px;
  top: 0;
}

#accounts-page .load {
  top: 200px;
}

/*.load {*/

/*position: fixed;*/

/*left: 0px;*/

/*top: 0px;*/

/*width: 100%;*/

/*height: 100%;*/

/*z-index: 9999;*/

/*background:transparent url("/static/assets/img/wheel.svg") center no-repeat;*/

/*}*/

#quick-tool {
  border-left: none;
  margin-left: 0px;
}
#quick-tool img {
  width: 20px;
}
#quick-formgroup {
  float: left;
}

#tools a.disabled,
#etctools a.disabled {
  display: none !important;
}

#attribution {
  position: absolute;
  /* bottom: 21px;
  right: 215px; */
  width: max-content;
}

#attribution a {
  display: inline;
  float: left;
  margin-right: 5px;
  text-align: center;
}

#powerchat {
  position: absolute;
  bottom: 0;
  right: 205px;
}

#zsiqbtn {
  position: absolute;
  bottom: 19px;
  right: 224px;
}

#chat-button {
  bottom: 18px;
  right: 222px;
  position: absolute;
  width: 64px;
  height: 26px;
  line-height: 14px;
}

#chat-button.btn:hover {
  background-color: #0c840f;
}

#chat-button.btn,
#chat-button.btn-primary {
  background-color: #1aa91e;
  border-radius: 1px;
  border-color: #0f5a11;
}

#chat-button a {
  color: #fff;
}

/*Zoom control styling*/

#zoom-control {
  left: 206px;
  position: absolute;
  top: 5px;
}

#zoom-control a {
  background-color: #f8f8f8;
  border: 1px solid #333333;
  color: #000000;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  height: 24px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  width: 24px;
  margin-bottom: 1px;
}

#zoom-control a:hover {
  background-color: #dddddd;
}

/*#zoom-control a:first-child {*/

/*    */

/*}*/

/*End Zoom control styling*/

#watermark {
  height: 22px;
  position: absolute;
  z-index: 99;
  left: 0;
  right: 0;
  bottom: 87px;
}

#map-controls {
  bottom: 26px;
  left: 206px;
  min-width: 110px;
  position: absolute;
}

#layer-map-controls {
  bottom: 45px;
  left: 25px;
  min-width: 110px;
  position: absolute;
}

#layer-map-controls > div {
  background-color: rgba(255, 255, 255, 0.85);
  font-size: 12px;
  padding: 5px;
  position: relative;
  height: 23px;
  margin-bottom: 3px;
  text-align: center;
}

#layer-map-controls p {
  padding: 0px;
}

#zoom-level-bound {
  bottom: 79px;
  left: 25px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  height: 48px;
  float: left;
  font-size: 12px;
  margin-right: 5px;
  padding: 5px;
  z-index: 999;
  text-align: center;
  font-weight: 700;
}

#map-controls > div {
  background-color: rgba(255, 255, 255, 0.85);
  font-size: 12px;
  padding: 5px;
  position: relative;
  height: 23px;
  margin-bottom: 3px;
  text-align: center;
}

/*Zoom level indicator styling*/

#zoom-level {
  padding-top: 4px;
  height: 22px;
}

div#zoom-level p {
  display: inline-block;
  margin: 0;
  text-align: center;
}

#zoom-level span {
  padding: 0px 5px;
}

div#scale-control {
  line-height: 1.228571;
  font-size: 10px;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 3px 4px 3px 4px;
  height: 32px;
}

.olImageLoadError {
  /* Die, pink tiles!  DIE! */
  display: none !important;
}

a.navbar-link {
  font-size: 10.5pt;
}

.accordion > h3 {
  cursor: pointer;
  margin: 15px 0px 5px 0px;
  overflow: auto;
  padding-left: 20px;
  padding-right: 10px;
}

#metrics-accordion.accordion > h3 {
  margin: 15px 5px 5px 0;
}

/* Layers */

#layers-panel .accordion,
.mapport-panel .wrapper .accordion {
  margin: 5px;
}

.zoom-layer-popup {
  background: transparent url("/assets/img/tools/zoom_popup.png") no-repeat
    scroll left top;
  font-size: 11px;
  font-weight: 700;
  height: 40px;
  padding: 10px 10px 5px 28px;
  position: fixed;
  width: 182px;
  z-index: 9999;
}

#layers-panel .disabled > label {
  color: #b2b2b2;
}

#layers-panel .accordion > h3,
.mapport-panel .wrapper .accordion > h3 {
  background: #e4e4e4 url("/assets/img/arrow-up.png") no-repeat scroll 96%
    center;
  cursor: pointer;
  font-size: 11pt;
  font-weight: 700;
  margin: 15px 0 5px;
  overflow: auto;
  padding: 6px 20px 6px 6px;
  width: 100%;
}

#layers-panel .accordion > h3.current,
.mapport-panel .wrapper .accordion > h3.current {
  background-image: url("/assets/img/arrow-down.png");
  margin-right: 7px;
}

#layers-panel .accordion h4 {
  background: url("/assets/img/arrow-up.png") no-repeat scroll 96% 10px;
  cursor: pointer;
  font-size: 10pt;
  margin: 15px 0 5px;
  overflow: auto;
  padding: 6px 20px 6px 6px;
  width: 100%;
}

#layers-panel .accordion h4.current {
  background-image: url("/assets/img/arrow-down.png");
  margin-right: 7px;
  font-size: 10pt;
}

#layers-panel p {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#layers-panel input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}

.legend-icon {
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  /* display: inline-block; */
  float: left;
  margin-left: 5px;
  min-height: 22px;
  width: 22px;
}

.legend-color {
  display: block;
  float: left;
  height: 16px;
  margin-left: 5px;
  margin-right: 7px;
  margin-top: 2px;
  width: 16px;
}

#layers-panel .accordion li {
  background-color: #ffffff;
  background-position: left center;
  background-repeat: no-repeat;
  border-bottom: 1px dotted #cccccc;
  margin-left: 0;
  min-height: 12px;
  padding: 4px;
}

#layers-panel .accordion .overlay-li.current {
  background: lightgray;
}

#layers-panel .accordion li.layers-sub-heading {
  border: medium none;
}

/*#layers-panel .accordion li.layers-sub-heading.last-class-group {*/

/*    border-bottom: 1px dotted #cccccc;*/

/*}*/

#layers-panel label {
  margin-left: 10px;
}

#layers-panel .layers-sub-heading > label {
  margin-left: 0;
}

#layers-panel h4 {
  background-color: #f8f8f8;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding: 9px;
}

#tool-panel .layers-sub-heading p {
  float: left;
  line-height: 1.4;
  margin: 0 !important;
  padding: 2px !important;
  width: 105px;
  text-overflow: clip;
  white-space: normal;
  text-align: left;
}

#tool-panel .legend-name {
  float: left;
  line-height: 1.4;
  margin: 0 !important;
  padding: 2px;
  width: 120px;
  text-align: left;
  text-overflow: clip;
  white-space: normal;
}

#layers-panel .layers-sub-heading {
  background-color: #ffffff;
  border-bottom: medium none;
}

#tool-panel .layers-sub-heading.disabled p {
  margin-left: 10px !important;
}

#layers-panel .overlay-li span.zoom-to-style {
  color: #333;
  font-size: 7pt;
  margin-left: 40px;
  position: relative;
  text-transform: uppercase;
}

#layers-panel .overlay-li span.zoom-to-style:hover {
  color: rgb(00, 133, 218);
}

/* ENd Layers */

#tool-panel p {
  padding: 0px 10px;
  text-align: left;
}

#map-controls p {
  padding: 0px;
}

/* Scale Bars */

.olControlScale {
  right: 3px;
  bottom: 3em;
  display: block;
  position: absolute;
  font-size: smaller;
}

.olControlScaleLine {
  display: block;
  position: absolute;
  left: 10px;
  bottom: 15px;
  font-size: xx-small;
  opacity: 0;
}

.olControlScaleLineBottom {
  border: solid 2px black;
  border-bottom: none;
  margin-top: -2px;
  text-align: center;
  white-space: pre;
  margin-right: auto;
  margin-left: auto;
}

.olControlScaleLineTop {
  border: solid 2px black;
  border-top: none;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

/* #greenbar {
  background-color: #0085da;
} */

#collapsable-nav-white ul li .navbar-text a {
  color: #0085da;
}

#collapsable-nav-white ul li .navbar-text {
  color: black;
}

#greenbar ul li a {
  background-color: white;
  border: 1px solid black;
  float: left;
  height: 26px;
  margin-left: 5px;
  margin-top: 8px;
  padding: 2px;
  width: 26px;
}

#greenbar ul li a.disabled-tool,
#info-panel .feature-panel .disabled-tool {
  border-color: #cccccc;
  padding: 0;
}

#greenbar ul li a.disabled-tool img,
#info-panel .feature-panel .disabled-tool > img {
  background: rgba(0, 0, 0, 0) none no-repeat scroll center center;
  width: 24px;
}

#tools a.current,
#etctools a.current {
  background-color: #aaaaaa;
  color: #ffffff;
}

.go-link.current {
  background-color: #c0c0c0;
  color: black;
}

#quick-tool {
  background-color: white;
  border: 1px solid black;
  display: block;
  float: left;
  height: 26px;
  padding: 2px;
  width: 26px;
  margin-left: -1px;
}

#quickform {
  margin-bottom: 4px;
  margin-top: 8px;
  padding: 0px 0px;
}

#quick-input {
  background-color: white;
  border: 1px solid black;
  height: 26px;
  width: 300px;
  min-width: 220px;
  padding: 4px 25px 4px 4px;
}

#greenbar ul li a:hover,
#search-close:hover,
#quick-tool:hover,
.go-link:hover {
  background-color: #cecece;
}

#greenbar .navbar-brand {
  color: white;
  height: 25px;
  margin-bottom: 3px;
  margin-right: 20px;
  margin-top: 10px;
  padding: 0 0 0 12px;
  font-size: 13.5pt;
}

.navbar-toggle {
  background-color: white !important;
}

#mapport {
  height: 28px !important;
  line-height: 20px !important;
  margin-left: -10px !important;
  margin-right: 1px;
  padding-bottom: 8px !important;
  padding-left: 8px !important;
  padding-right: 7px;
  padding-top: 8px !important;
}

#map-link,
#map-link:hover {
  text-decoration: none;
  color: #0085da;
}

.home-icon {
  background: transparent url("/static/assets/img/home-off.png") no-repeat
    scroll center center;
  display: block;
  float: left;
  height: 26px;
  margin-top: 2px;
  width: 26px;
}

.home-icon:hover {
  background-image: url("/static/assets/img/home-on.png");
}

.whitebar {
  min-height: 40px !important;
}

.whitebar .navbar-nav > li > a,
.whitebar .navbar-nav > li > p {
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 18px;
}

.whitebar .navbar-nav > li {
  height: 35px !important;
  padding-top: 5px;
}

#enplan-user .navbar-text {
  margin-top: 8px;
}

.whitebar .navbar-nav > li::after,
#main-nav > li::after {
  content: " | ";
  position: absolute;
  top: 11px;
}

.whitebar .navbar-nav > li:last-child:after,
#main-nav > li:last-child:after {
  content: none;
}

.bluebar,
.navbar.navbar-default.bluebar {
  min-height: 41px !important;
}

#help-panel .content {
  padding: 5px;
}

#field-table img.grip {
  cursor: move;
}

/*
* GeoServer Layer Styling
*/

/*#style_panel*/

table#geoserver-layer-styling {
  width: 100%;
}

.close-layer-style img {
  cursor: pointer;
  padding-top: 7px;
}

.close-layer-style {
  width: 25px;
}

#geoserver-layer-styling th {
  text-align: center;
}

#loading-layer {
  background: transparent url("/static/assets/img/loading-squares.gif")
    no-repeat scroll center center;
  height: 100px;
  margin: 0 auto;
  position: relative;
  width: 100px;
  z-index: 900;
}

.layer-action-buttons {
  height: 50px;
  padding-right: 5px;
  padding-top: 10px;
  position: static;
}

.layer-action-buttons .form-group {
  bottom: 0;
  float: right;
}

#layer-new-style,
#layer-default-style {
  margin: 1em;
}

#layer-new-style label,
#layer-default-style label {
  padding-top: 8px;
}

#layer-default-style .btn.btn-primary {
  float: right;
  margin-right: 1.3em;
  margin-top: 0.5em;
}

#style-control {
  margin-left: 2em;
  margin-top: 1em;
}

#style_panel {
  margin-top: 6%;
  padding-bottom: 60px;
}

/*
* End GeoServer Layer Styling
*/

@media (max-width: 936px) and (min-width: 769px) {
  .whitebar.container-fluid .navbar-header {
    display: block;
  }
}

@media all and (max-width: 369px) {
  #quick-input {
    width: 200px;
  }
}

@media all and (max-width: 767px) and (min-width: 150px) {
  #zoom-control {
    top: 15px;
  }
  #tool-panel,
  #info-panel,
  .mapport-panel {
    top: 90px;
  }
}

.btn {
  border-radius: 2px;
  text-align: center;
  padding: 3px 18px;
}

.tab-content {
  margin-top: 15px;
}

button#new-del-map {
  width: 89px;
  color: white;
  border: 1px outset;
}

a#new-map {
  border: 1px outset;
}

/* Delete Modal Changes */

#myModal .modal-dialog {
  margin: 0 !important;
  left: 30%;
  top: 25%;
  width: 480px;
}

#myModal .modal-content {
  border-radius: 0;
  border: 2px solid black;
  box-shadow: 10px 14px 7px 2px rgba(0, 0, 0, 0.43);
}

#myModal .modal-body {
  height: 70%;
  padding: 0 15px;
}

#myModal .modal-body {
  font-family: "Segoe UI";
}

#myModal h3 {
  font-size: 18px;
}

#myModal .modal-footer {
  padding: 6px 15px;
}

/* Footer CSS */

.content {
  min-height: 80%;
}

#footer .container {
  border-top: 1px solid #333333;
  padding-bottom: 15px;
}

/*Chat Button Css*/

#chat-button-footer {
  background-color: green;
  right: 96px;
  position: absolute;
  width: 80px;
  margin-top: -35px;
}

/* welcome pop up Css */

nav.navbar.navbar-default {
  border: none;
}

#mapport-notification .alert-success,
#mapport-notification .alert-warning {
  border-radius: 0px;
  color: #555555;
  background-color: #e6e6e6;
  border-color: #555555;
  border-style: solid;
  border-width: 1px;
}

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
  color: #555555;
}

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: #555555;
}

#subscribed-maps-container h3,
#shared-maps-container h3 {
  color: #337ab7;
}

#subscribed-maps-container hr,
#shared-maps-container hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}

.col-md-12.recuring {
  margin-top: 4%;
  margin-bottom: 25px;
}

.container.col-md-6 h3 {
  color: #337ab7;
}

/* My Account Page
#accounts-page .col-md-12.recuring{margin-top: 4%;margin-bottom: 25px;}
*/

#accounts-page .container.col-md-6 h3 {
  color: #337ab7;
}

#accounts-page .col-md-12.form {
  margin-left: -14px;
}

#accounts-page input.col-md-3.form,
.col-md-4 {
  margin-bottom: 12px;
  padding-bottom: 0px;
}

#accounts-page .col-md-12.payment-edit {
  margin-bottom: 15px;
}

#accounts-page .profile-heading {
  color: #337ab7;
  font-size: 15pt;
  border-bottom: solid 1px lightgrey;
}

#accounts-page .row-payment {
  margin-top: 3%;
}

#accounts-page #country {
  width: 20%;
}

#accounts-page #state {
  width: 36%;
}

#accounts-page .postal-inputs {
  border: 1px solid black;
  margin-bottom: 2%;
  padding-left: 3px;
  width: 40%;
}

/*Pagination*/

ul.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

ul.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pv-map-cls {
  background-color: #3d9970;
}

.fdp-map-cls {
  background-color: #6a5acd;
}

.custom-map-cls {
  background-color: deepskyblue;
}

.gn-map-cls {
  background-color: purple;
}

.wv-map-cls {
  background-color: #ff851b;
}

.subscribed-map-types {
  color: white;
  font-weight: 700;
}

input.col-md-3.form,
.col-md-4 {
  margin-bottom: 12px;
  padding-bottom: 0px;
}

.hidden-profile,
.hidden-payment {
  display: none;
}

.hidden-password-form {
  display: none;
  margin-top: 2%;
  margin-left: 0%;
}

.profile {
  display: block;
}

.payment {
  display: block;
}

.profile-update-inputs {
  border: 1px solid black;
  margin-bottom: 2%;
  padding-left: 3px;
}

.profile-update-inputs#firstname {
  margin-right: 4%;
  width: 48%;
}

.profile-update-inputs#lastname {
  width: 48%;
}

/*input#recurly-hosted-field-input {*/

/*position: relative;*/

/*width: 100%;*/

/*border: 2px solid #c2c2c2;*/

/*background: transparent;*/

/*padding: 0.5rem;*/

/*margin: 0 0 1rem;*/

/*outline: none;*/

/*font-family: 'Open Sans', Helvetica, sans-serif;*/

/*font-size: 11pt;*/

/*font-weight: bold;*/

/*box-shadow: none;*/

/*border-radius: 0;*/

/*color: #c2c2c2;*/

/*-webkit-appearance: none;*/

/*-webkit-transition: border-color 0.3s;*/

/*-moz-transition: border-color 0.3s;*/

/*-ms-transition: border-color 0.3s;*/

/*-o-transition: border-color 0.3s;*/

/*transition: border-color 0.3s;*/

/*}*/

.errors_div {
  background-color: lightgrey;
  border: 1px solid black;
}

input.error {
  border-color: #e43c29;
}

div.error .recurly-hosted-field {
  border: 1px solid #e43c29;
}

div.date {
  display: inline-block;
  width: 2.5rem;
  /* float: left; */
  text-align: left;
  margin-right: -2px;
}

/*.recurly-hosted-field {*/

/*position: relative;*/

/*width: 100%;*/

/*height: 24px;*/

/*border: 1px solid black;*/

/*background: transparent;*/

/*margin-bottom: 8px;*/

/*font-family: 'Open Sans', Helvetica, sans-serif;*/

/*font-size: 11pt;*/

/*font-weight: bold;*/

/*box-shadow: none;*/

/*border-radius: 0;*/

/*color: #c2c2c2;*/

/*-webkit-appearance: none;*/

/*-webkit-transition: border-color 0.3s;*/

/*-moz-transition: border-color 0.3s;*/

/*-ms-transition: border-color 0.3s;*/

/*-o-transition: border-color 0.3s;*/

/*transition: border-color 0.3s;*/

/*}*/

#lock-recur p {
  color: rebeccapurple;
  padding-top: 5px;
  padding-left: 20px;
}

#lock-recur p img {
  width: 20px;
  position: absolute;
  left: 0;
}

.profile-update-inputs#first_name {
  margin-right: 2%;
  width: 48%;
}

.profile-update-inputs#last_name {
  width: 48%;
}

.recurly-hosted-field.recurly-hosted-field-year,
.recurly-hosted-field.recurly-hosted-field-month {
  width: 87%;
  height: 23px;
  margin-top: -2%;
}

.recurly-hosted-field.recurly-hosted-field-cvv {
  width: 84%;
  margin-left: 3%;
  height: 22px;
}

.col-md-6.year {
  padding: 0px;
}

.recurly-hosted-field.recurly-hosted-field-number {
  height: 23px;
  width: 84%;
  margin-top: -1px;
}

p.credit-icons {
  margin-top: -1%;
  margin-left: 2%;
}

.credit-icons img {
  width: 40px;
  height: 21px;
  margin-top: 7%;
  margin-left: 3%;
}

a#cancel-payment {
  text-decoration: none;
  /*padding: 2px 20px 2px 20px;*/
}

a#cancel-payment:hover {
  color: black;
}

.hide-subscription-items {
  display: none;
}

.hide-subscription-items-addons {
  display: none;
}

.hide-current-col-items {
  display: none;
}

.show-subscription-items {
  display: block;
  padding-left: 10px;
}

div#opt-seats {
  position: relative;
  float: right;
  right: 17px;
  top: 6px;
}

#opt-seats img {
  position: absolute;
}

#opt-seats img#down {
  top: 8px;
}

#opt-seats img#down2 {
  top: 8px;
}

#opt-seats img#down3 {
  top: 8px;
}

#opt-seats img#down4 {
  top: 8px;
}

/*.profile-cancel,.profile-edit{*/

/*margin-left: 0%;*/

/*width: 87px;*/

/*margin-top: 10px;*/

/*color: black;*/

/*text-align: center;*/

/*text-decoration: none;*/

/*border-radius: 0;*/

/*background-color: #e6e6e6;*/

/*border: 1px solid black;*/

/*cursor: pointer;*/

/*padding: 0px 20px 0px 20px;*/

/*}*/

.recurring-cancel {
  margin-left: 0%;
  width: 87px;
  margin-top: 10px;
  color: black;
  text-align: center;
  text-decoration: none;
  border-radius: 0;
  background-color: #e6e6e6;
  border: 1px solid black;
  cursor: pointer;
  padding: 2px 20px 2px 20px;
}

.recurring-update {
  text-decoration: none;
}

a#update-subscription {
  text-decoration: none;
  color: black;
}

/*.payment-update{*/

/*margin-left: 1.3%;*/

/*width: 87px;*/

/*margin-top: 5px;*/

/*color: black;*/

/*text-align: center;*/

/*border-radius: 0;*/

/*background-color: #e6e6e6;*/

/*border: 1px solid black;*/

/*cursor: pointer;*/

/*padding: 0px 20px 0px 20px;*/

/*}*/

.payment-cancel {
  margin-left: 0%;
  color: black;
  text-align: center;
  background-color: #e6e6e6;
  border: 1px solid black;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  padding: 0px 20px 0px 20px;
}

#row-button {
  margin-top: 2%;
}

.update-profile {
  padding: 0px 15px 0px 15px;
  width: 53px;
  margin-top: 10px;
  text-align: center;
  background-color: #e6e6e6;
  border: 1px solid black;
  cursor: pointer;
  margin-left: 30px;
}

.recur-button {
  margin-left: 3.5%;
  color: black;
  border-radius: 0;
  background-color: #e6e6e6;
  border: 1px solid black;
  padding: 0px 15px 0px 15px;
}

p#connect-icons {
  margin-left: 3%;
}

/*button#card-edit {margin-left: 3%;margin-bottom:2%;color: black; cursor: pointer;text-align: center;}*/

a.update-subscription {
  text-decoration: none;
  text-align: center;
  margin-left: 5%;
  width: 87px;
  color: black;
  padding: 5px 10px 5px 10px;
  border-radius: 0;
  background-color: #e6e6e6;
  border: 1px solid black;
  cursor: pointer;
  text-decoration: none;
}

button.update-subscription {
  text-align: center;
  margin-left: 5%;
  width: 87px;
  color: black;
  padding: 5px 10px 5px 10px;
  border-radius: 0;
  background-color: #e6e6e6;
  border: 1px solid black;
  cursor: pointer;
  text-decoration: none;
}

p.payment-label {
  font-size: 10px;
  margin-bottom: 2px;
}

p.payment-yy-mnth {
  font-size: 10px;
  margin-bottom: 2px;
  margin-left: -2%;
}

#change-password {
  cursor: pointer;
}

/*.plan-name { margin-left: 5px; }*/

div#plan-name {
  margin-top: -15px;
  margin-left: 0px;
}

#subscription-remove {
  margin-left: 107px;
}

/*form#subscription-removal { margin-left: -2%;}*/

/**********************************     Recurly Plans     ************************************/

#recurly_plan_id table {
  width: 82%;
  float: right;
}

#recurly_plan_id select {
  width: 100px;
  margin: 5px;
}

#recurly_plan_id input {
  margin: 5px;
}

#recurly_plan_id #selectDaily,
#recurly_plan_id #selectMonthly,
#recurly_plan_id #selectYearly,
#recurly_plan_id #select6Months {
  width: 300px;
}

/**********************************     Navigation bar     ************************************/

#nav-maps > li > a:hover {
  background-color: transparent;
  text-decoration: none;
}

#nav-maps > li > a {
  margin-right: 2px;
  line-height: 0.42857143;
  border: 0px solid transparent;
  border-radius: 4px 4px 0 0;
  color: #337ab7;
  background-color: transparent;
}

#nav-maps > li.active > a,
#nav-maps > li.active > a:focus,
#nav-maps > li.active > a:hover {
  color: black;
  cursor: default;
  background-color: #e6e6e6;
  border: 1px solid lightgrey;
  border-bottom-color: transparent;
  border-radius: 0px;
  height: 25px;
}

select#id_address2,
#id_owner_name,
#id_address1,
#id_apn,
#id_situs_addr_One,
#id_situs_addr_Two,
#id_mailing_addr_One,
#id_mailing_addr_Two {
  width: 207px;
  height: 27px;
}

select#id_address2 option:disabled,
#id_owner_name option:disabled,
#id_address1 option:disabled,
#id_apn option:disabled,
#id_situs_addr_One option:disabled,
#id_situs_addr_Two option:disabled,
#id_mailing_addr_One option:disabled,
#id_mailing_addr_Two option:disabled {
  color: #333336;
}

input#form_tabel,
#form_label {
  margin: 25px 0px;
}

p#apn_text,
#OwName,
#addr1,
#addr2,
#situsadd1,
#situsadd2,
#mailadd1,
#mailadd2 {
  font-weight: bold;
}

.label_names_margin {
  margin-bottom: 0px;
}

.label_name_div_margin {
  margin-bottom: 15px;
}

.sld-title {
  margin-left: 3%;
  font-size: 18px;
  margin-top: 2%;
  text-decoration: underline;
}

.sld_basemap_body {
  padding: 2px 30px;
}

select.basemap_sld_select {
  display: inline;
  float: right;
  width: 27%;
  margin-right: 60%;
  margin-top: -33px;
}

select.basemap_sld_select_1 {
  display: inline;
  float: right;
  width: 27%;
  margin-right: 60%;
  margin-top: -33px;
}

select.basemap_sld_select_style {
  display: inline;
  float: right;
  width: 27%;
  margin-right: -56%;
  margin-top: -34px;
}

.sld_basemap_select_head {
  font-weight: 700;
  margin-left: 15%;
  padding-bottom: 15px;
}

.sld_basemap_select_head:last-child {
  margin-left: 10%;
}

.sld_basemap_select_head:nth-child(2) {
  margin-left: 10%;
}

#save_basemap_style {
  float: right;
  margin-right: 5%;
}

input#icon-url-text {
  width: 27%;
  display: inline;
  margin-right: 2%;
  float: right;
  margin-top: -35px;
}

label.label-basemaps {
  width: 12.5%;
  margin-right: 10px;
  text-align: left;
}

.form-group.basemap-style-obj {
  margin-bottom: 25px;
}

.form-control {
  font: 16px "Segoe UI", "Open Sans", arial, sans-serif;
  font-weight: 400;
}

.login-mail {
  background-image: url(/assets/img/tools/user-icon.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-origin: content-box;
  background-size: 16px;
  border-radius: 0;
  height: 40px;
  margin-bottom: 30px;
}

.login-password {
  background-image: url(/assets/img/tools/lock-icon.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-origin: content-box;
  background-size: 16px;
  border-radius: 0;
  height: 40px;
  margin-bottom: 30px;
}

.modal-padding {
  padding: 0px 15px 15px 15px !important;
}

.button-wrapper {
  text-align: center;
}

.modal-button-style {
  border: 0.5px solid lightgray;
  background: transparent;
  box-shadow: none;
  border-radius: 3px;
  height: 30px;
}

.paragraph-style {
  margin: 0px;
}

#callout {
  background-color: white;
  color: black;
  position: absolute;
  bottom: 21px;
  right: 376px;
  padding: 10px;
  z-index: 20000;
  height: 48px;
  width: 200px;
  opacity: 0.8;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  -moz-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  -moz-border-radius: 4px;
}

#windyMap {
  position: absolute;
  z-index: 2000;
  pointer-events: none;
}

.list-deco {
  display: inline-block;
  margin-left: 6px;
  padding: 5px 12px;
  font-size: 16px;
  font-weight: 400;
  height: 32px;
  cursor: pointer;
}

.list-deco a {
  font-size: 16px;
  line-height: 1;
  color: #555;
}

#user-button {
  color: #2c84c6;
}

.menu-button-subscribe {
  background: #2c84c6;
}

.menu-button-subscribe a {
  color: white;
}

.login-head {
  font-size: 18px;
  color: #555555;
  font-weight: bolder !important;
  font-family: "Segoe UI", "Open Sans", arial, sans-serif !important;
}

.login-head a {
  text-decoration: none;
  color: #555555;
}

.login-subhead {
  margin: -10px 0 0px;
  font-size: 14px;
  font-size: 11px;
  color: #606060;
}

.login-head-cu {
  font-size: 20px;
  color: #555555;
  font-weight: bolder !important;
  font-family: "Segoe UI", "Open Sans", arial, sans-serif !important;
}

.login-subhead-cu {
  margin: -10px 0 0px;
  font-size: 17px;
  font-size: 11px;
  color: #606060;
}

.login-subhead a {
  color: #606060;
  text-decoration: none;
}

.div-window_maps {
  padding: 10px;
  border: solid 1px #cccccc;
  width: 40%;
  margin-bottom: 50px;
  margin-left: 39%;
  margin-top: 50px;
}

.div-window_maps_title {
  padding: 10px;
  border: solid 1px #cccccc;
  width: 40%;
  margin-left: 39%;
  margin-top: 50px;
}

#subscription-removal .directly-login-settings {
  width: 45%;
  margin-bottom: 50px;
  margin-left: 39%;
  margin-top: 50px;
  font-family: sans-serif;
  border: solid 1px #cccccc;
  padding: 0px 0px 0px 15px;
}

#trustwaveSealImage {
  height: 30px;
}

.poweredbyrecurly {
  margin-left: 30px;
  margin-right: 30px;
}

.sub-main-head {
  font-size: 36px;
  font-weight: 300 !important;
}

.sub-head-pv {
  font-size: 24px;
  border-bottom: solid 1px #0085da;
}

.sub-head-wv {
  font-size: 24px;
  border-bottom: solid 1px #f38220;
}

.p2 {
  font-family: "Segoe UI", "Open Sans", arial, sans-serif !important;
  font-weight: 400;
  color: #555555;
  font-size: 16px;
  display: inline;
  margin-right: 15px;
}

.p2Simple {
  font-family: "Segoe UI", "Open Sans", arial, sans-serif !important;
  font-weight: 400;
  color: #555555;
  font-size: 16px;
}

.add-ons-div {
  font-family: "Segoe UI", "Open Sans", arial, sans-serif !important;
  display: inline;
  font-size: 16px;
  font-weight: 300;
}

#quant {
  font-size: 18px;
  display: inline;
  float: left;
  margin-top: 5px;
  margin-left: -150px;
}

#cancel-seats {
  margin-top: 0px;
}

.gernal-btn {
  border-radius: 0;
  font-size: 16px;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #ececec;
  color: #555;
}

.Pending-collaborater-invite .input-group-btn .btn {
  padding: 2px 10px;
}

.PendingCancel {
  margin-left: 20px !important;
}

.line-margin {
  margin-bottom: 15px;
}

.colab-custom-options {
  display: none;
  padding-top: 10px;
  padding-bottom: 20px;
}

.colab-drop-down {
  padding: 4px;
  background: none;
  border: 1px solid #ccc;
  width: 40%;
  margin-bottom: 10px;
}

.more-option-text {
  background: none;
  outline: none;
  border: none;
  border-radius: 0;
  font-size: 16px;
  padding-right: 20px;
  padding-top: 10px;
  float: right;
  margin-top: 20px;
  color: #0085da;
}

.back-btn-right {
  display: none;
  float: right;
  border-radius: 0;
  font-size: 16px;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #ececec;
  color: #555;
}

.text-btn {
  background: none;
  border: none;
  color: #0085da;
  float: right;
  padding: 0;
  font-size: 16px;
  font-weight: 300;
  outline: none;
}

#acc-seats img {
  margin-top: 6px;
}

#acc-seats {
  display: inline;
}

.up {
  position: absolute;
}

#your-sub-maps,
#your-sub-addons,
#your-col-invite,
#your-share-maps {
  display: none;
}

#collab-seats {
  font-size: 15px;
  display: inline;
  margin-left: 68px;
}

.history-table {
  width: 100%;
  font-size: 18px;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-weight: 300;
  color: #555;
  text-align: center;
}

.history-table-filter {
  background-image: url(/assets/img/pv/quick.png);
  background-repeat: no-repeat;
  background-position: right center;
  background-origin: content-box;
  background-size: 16px;
  border-radius: 0;
  height: 40px;
  margin-bottom: 30px;
}

.pagination > .active > span {
  color: #555;
}

#activity_response_history {
  max-height: 500px;
  overflow-y: scroll;
}

#users-activity-history {
  font-size: 14px;
  color: #0b0b0b;
}

#users-activity-history th,
td {
  text-align: center;
  padding: 7px !important;
}

#tbl-maps td {
  text-align: unset;
}

.dynamic-btn {
  border-radius: 0px;
  margin-top: 10px;
  margin-right: 5px;
}

#p1-bg {
  background-image: url(/assets/img/PV1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  background-size: 380px;
}

#p2-bg {
  background-image: url(/assets/img/WV21.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  background-size: 380px;
}

#p3-bg {
  background-image: url(/assets/img/WV20.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  background-size: 380px;
}

.choose-prod-title {
  font-size: 32px;
  margin: 20px 50px;
  font-weight: 300 !important;
}

.choose-prod-content.content {
  min-height: 50%;
  margin-top: 5%;
}

.choose-prod-content .product-choice {
  display: inline-block;
  width: 20%;
}

.choose-prod-content .product-choice img {
  width: 150px;
}

.choose-prod-content .product-choice span {
  display: block;
  font-size: 16px;
  margin: 20px 0px;
}

#tool-panel #wind-info {
  display: none;
}

/****  removing bootstrap responsive menu and managing nav elements customly ****/

.navbar-collapse.collapse {
  display: block;
}

.navbar-nav > li,
.navbar-nav {
  float: left;
}

.navbar-right {
  float: right;
}

.navbar-toggle {
  display: none;
}

ul.whitebar-nav li.logout img {
  width: 18px;
}

ul.whitebar-nav li #access-level-menu {
  font-size: small;
}

ul.whitebar-nav li #access-level-menu select#selected-level {
  background: #f8f8f8;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: small;
  width: 100px;
  margin-right: -40px;
  padding-left: 10px;
}

ul.whitebar-nav li #access-level-menu select#selected-level option {
  font-size: medium;
}

.bluebar .container-fluid .navbar-collapse #tools-back-icon {
  display: none;
  background-color: white;
  border: 1px solid black;
  float: left;
  height: 26px;
  margin-left: 5px;
  margin-top: 8px;
  padding: 2px;
  width: 26px;
}

.bluebar .container-fluid.basic-mode {
  padding-left: 0px;
}

.bluebar .container-fluid.basic-mode .navbar-collapse {
  padding-left: 0px;
}

.bluebar .container-fluid .navbar-collapse #tools-collapse-expand img {
  float: left;
  height: 30px;
  margin-left: -22px;
  margin-top: 6px;
  width: 26px;
  cursor: pointer;
}

.bluebar .container-fluid .navbar-collapse #tools-back-icon img {
  width: 20px;
}

.bluebar #tools.floatRight {
  float: right !important;
}

.bluebar .right-elements {
  display: inline-block;
  /* float: right; */
}

.bluebar .right-elements #quickform {
  display: inline-block;
}

.bluebar .right-elements #etctools {
  display: inline-block;
  /* float: right; */
}

.whitebar .navbar-nav > li.logout.show-logo::after {
  content: none;
}
.whitebar .navbar-nav > li.logout.logo::after {
  content: none;
}

.whitebar .navbar-nav > li.mymaps.lastChild::after {
  content: none;
}

@media all and (max-width: 767px) {
  .whitebar .navbar-header {
    display: inline-block;
    margin-right: 0px;
    margin-left: 0px;
  }
  .whitebar #collapsable-nav-white {
    display: inline-block;
    margin-right: 0px;
    margin-left: 0px;
    /* float: right; */
    border-top: none;
    padding-left: 0px;
  }
  .whitebar #collapsable-nav-white .whitebar-nav .navbar-text {
    float: left;
    margin-right: 15px;
    margin-left: 15px;
  }
  .whitebar #collapsable-nav-white .whitebar-nav li:first-child p {
    margin-left: 0px;
  }
  .whitebar .navbar-nav {
    margin: 0 -15px 0 0;
  }
  ul.whitebar-nav li.logout img {
    width: 18px;
  }
  .bluebar .navbar-collapse {
    border-top: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .bluebar #quickform {
    border-top: none;
    border-bottom: none;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  .bluebar #quickform #quick-input {
    width: 280px;
  }
  .bluebar .navbar-nav {
    margin: 0px;
  }
  .bluebar .navbar-nav.navbar-right {
    margin-right: -15px;
  }
  .bluebar .container-fluid .navbar-collapse {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .bluebar .container-fluid .navbar-collapse .navbar-form {
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .bluebar ul#tools li:first-child a {
    margin-left: 0px;
  }
}

@media all and (max-width: 390px) {
  .bluebar .navbar-header .navbar-brand {
    display: none;
  }
}

@media all and (max-width: 767px) {
  .bluebar #quickform #quick-input {
    width: 200px;
  }
}

/****  removing bootstrap responsive menu and managing nav elements customly ****/

.bluebar .tools li a > img {
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
  margin-bottom: 1px;
}

.bluebar .tools li a span.tooltiptext img {
  width: 15px;
  height: 15px;
}

/*fdp header temprory fix for production*/

.fdpviewer > ul > li:first-child {
  padding: 0px;
}

#product_type {
  text-align: left;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
}

#contactuspopup-a {
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

#export-limit .modal-header {
  border-bottom: none;
  font-size: 25px;
  font-weight: 600;
}

#export-limit .modal-content {
  height: 225px;
  border: 1px solid black;
  border-radius: 5px;
  margin: auto;
}

#export-limit .modal-footer {
  padding-top: 5px;
}

#export-limit .modal-button-style {
  padding: 6px 19px;
}

#select-limit .modal-header {
  border-bottom: none;
  font-size: 25px;
  font-weight: 600;
}

#select-limit .modal-content {
  height: 155px;
  border: 1px solid black;
  border-radius: 5px;
  margin: auto;
}

#select-limit .modal-footer {
  padding-top: 5px;
}

#select-limit .modal-button-style {
  padding: 4px 19px;
}
.my-tooltip {
  white-space: pre-line;
  font-size: 12px;
  background-color: white;
  color: #000 !important;
  border: 1px solid;
}

ul li.active > a {
  background: transparent;
  color: #c78891;
}

#greenbar .navbar-nav li > a:hover,
#greenbar .navbar-nav li > a:focus {
  background-color: #cecece;
  color: #fff;
}
#greenbar .navbar-nav li.active > a {
  background-color: #cecece;
  color: white;
}
/* .tooltip {
  width: 200px !important;
  white-space: pre-wrap;
  border: solid black 1px !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  max-width: 200px !important;
  padding: 5px;
  font-size: 9pt;
  line-height: normal;
  pointer-events: none;
  transition-property: opacity;
  text-align: left !important;
  position: relative;
  top: 17% !important;
  left: 10% !important;
} */

.ol-control button {
  background-color: red;
}
.mat-dialog-container {
  display: block;
  padding: 0px !important;
  overflow: hidden !important;
}
.drag {
  background-color: rgba(255, 255, 255, 0.4) !important;
  border-color: rgb(150, 0, 0) !important;
}
.mat-drawer-inner-container {
  overflow: visible !important;
}
/* .ol-attribution:not(.ol-collapsed) {
  background: #b4bab6 !important;
} */
.ol-attribution:not(.ol-collapsed) {
  background: #ffffff !important;
}

.ol-attribution {
  font-size: 10px !important;
  border-radius: 0px 0 0 !important;
  opacity: 0.7 !important;
  font-weight: 500 !important;
}
.ol-attribution ul li a {
  color: #000000 !important;
  font-weight: 500 !important;
}
