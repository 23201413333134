/* You can add global styles to this file, and also import other style files */
/* @import "./styles/_angular-material.scss"; */
/* @import "./styles/_bootstrap.scss"; */
/* Importing Bootstrap SCSS file. */
/* @import '~bootstrap/css/bootstrap'; */

html,
body {
  min-height: 100vh !important;
  height: auto !important;
  background-color: #fafafa;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Segoe UI", "Open Sans", arial, sans-serif !important;
}

/* Fix Left Side Panel height for making it scrollable */
.mat-drawer-inner-container {
  width: 100% !important;
  height: 99% !important;
  overflow: auto !important;
  -webkit-overflow-scrolling: touch !important;
}

input,
button,
select,
textarea {
  margin-bottom: 20px !important;
  margin-right: 5px !important;
}

.mat-dialog-title {
  padding-left: 5px !important;
}

.mat-list-item-content {
  padding-left: 0 !important;
  height: 33px !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
  margin-top: 1px !important;
}
.logo-heading {
  height: 36px !important;
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}
.mat-expansion-panel-body {
  margin: 5px !important;
  padding: 0 !important;
  width: 93% !important;
}
.mat-list-option:hover,
.mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: transparent !important;
  cursor: text !important;
}

mat-icon {
  color: #858585 !important;
}

/* Remove Extra Padding Inside MatDilog */
.app-full-bleed-dialog .mat-dialog-container {
  box-shadow: none !important;
  background: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5) !important;
}

.app-full-bleed-dialog1 .mat-dialog-container {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5) !important;
  background-color: white !important;
}

.bdrop {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.bdrop1 {
  background-color: none !important;
}

.bdropDark {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.bdropDark1 {
  background-color: rgba(0, 0, 0, 0.5) !important;
  overflow: visible !important;
}
/* remove padding from sidenav */
.mat-list-base {
  padding-top: 0 !important;
}

/* for only welcome modal */
div.modal-body1 a:visited {
  outline: none !important;
  color: #337ab7 !important;
  text-decoration: none !important;
  border: 0 !important;
}

div.modal-body1 a:focus {
  outline: none !important;
  color: #337ab7 !important;
  text-decoration: none !important;
  border: 0 !important;
}

div.modal-body1 span {
  color: #555555 !important;
}

div.modal-body1 a:hover {
  outline: none !important;
  color: #337ab7 !important;
  text-decoration: none !important;
  border: 0 !important;
}

div.modal-body1 a:active {
  outline: none !important;
  color: #337ab7 !important;
  text-decoration: none !important;
  border: 0 !important;
}

div.modal-body1 a:hover {
  color: #23527c !important;
  text-decoration: underline !important;
}

/* prevent pushing sidenav when modal open */
.cdk-global-scrollblock {
  position: static !important;
  width: initial !important;
  overflow-y: inherit !important;
}

/* For Feature List  */
div.labelData a {
  color: #0085da !important;
  font-size: 12px !important;
}

div.labelData a:visited {
  outline: none !important;
  color: #337ab7 !important;
  text-decoration: none !important;
  border: 0 !important;
}

div.labelData a:focus {
  outline: none !important;
  color: #337ab7 !important;
  text-decoration: none !important;
  border: 0 !important;
}

div.labelData span {
  color: #555555 !important;
}

div.labelData a:hover {
  outline: none !important;
  color: #337ab7 !important;
  text-decoration: none !important;
  border: 0 !important;
}

div.labelData a:active {
  outline: none !important;
  color: #337ab7 !important;
  text-decoration: none !important;
  border: 0 !important;
}

div.labelData a:hover {
  color: #23527c !important;
  text-decoration: underline !important;
}

/* for information tool */
div#help-panel a {
  color: #0085da;
}

div#help-panel a:visited {
  outline: none;
  color: #337ab7;
  text-decoration: none;
  border: 0;
}

div#help-panel a:focus {
  outline: none;
  color: #337ab7;
  text-decoration: none;
  border: 0;
}

div#help-panel p:first-of-type {
  margin-top: 11px;
}

div#help-panel a:hover {
  outline: none;
  color: #337ab7;
  text-decoration: none;
  border: 0;
}

div#help-panel a:active {
  outline: none;
  color: #337ab7;
  text-decoration: none;
  border: 0;
}

div#help-panel a:hover {
  color: #23527c;
  text-decoration: underline;
}

div#help-panel p {
  margin: 0;
  margin-bottom: 10px;
}

div#help-panel p:last-of-type {
  padding-bottom: 7.5px;
}
div#help-panel {
  font-family: arial !important;
  color: #333 !important;
}

/* for addOns and collab text */

@media only screen and (min-width: 1920px) {
  #addons-dashboard-text span {
    font-size: 21px !important;
  }
  #collab-dashboard-text p {
    font-size: 21px !important;
  }
}
.mat-dialog-container {
  overflow: auto !important;
  height: auto !important;
}

.mat-dialog-container {
  overflow: auto !important;
  height: auto !important;
}

/* for mobile devices update modal responsivness */
@media only screen and (max-width: 900px) {
  .app-full-bleed-dialog .mat-dialog-container {
    box-shadow: none !important;
    margin-left: -7% !important;
    margin-top: 85% !important;
  }
}
@media only screen and (max-width: 600px) {
  .app-full-bleed-dialog .mat-dialog-container {
    box-shadow: none !important;
    margin-left: -35% !important;
    margin-top: 74% !important;
  }
}

/* for element visibility  */
#map canvas {
  min-height: 100% !important;
}

.mat-list-item-content {
  padding: 0 !important;
}

/* https://ngx-colors.web.app/ color picker global css  */
.app-color-picker .preview {
  border-radius: 2px !important;
  width: 63px !important;
}

.app-color-picker {
  display: inline !important;
}

.app-color-picker .preview .circle {
  width: 57px !important;
  border-radius: 2px !important;
}
